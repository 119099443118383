import { selector } from 'recoil';
import getEvaEndpoint from '../../helpers/get-eva-endpoint';
import evaEndpointUrlState from './eva-endpoint-url-state';

const evaApplicationConfigurationState = selector({
  key: 'evaApplicationConfiguration',
  get: async ({ get }) => {
    const evaEndpointUrl = get(evaEndpointUrlState);

    if (!evaEndpointUrl) {
      return;
    }

    const evaEndpoint = await getEvaEndpoint(evaEndpointUrl);
    return evaEndpoint.getApplicationConfiguration();
  },
});

export default evaApplicationConfigurationState;
