import { EvaStorage } from '@springtree/eva-sdk-core-storage';
import { EvaEndpoint, IEvaEndpointSerializedData } from './endpoint';
import { logger } from '@springtree/eva-sdk-core-logger';
import { getServiceSettings } from './settings';

/**
 * The storage key used to store the endpoints cache
 *
 * @export
 * @interface IEvaEndpointStorage
 */
export interface IEvaEndpointStorage {
  evaEndpointCache: string;
}

/**
 * This is the object stored in the above storage key.
 * After JSON parsing the string it should match this interface
 *
 * @export
 * @interface IEvaEndpointsCache
 */
export interface IEvaEndpointsCache {
  [endpointUrl: string]: IEvaEndpointSerializedData;
}

// The bootstrap cache (ie endpoint registry)
// To prevent excessive bootstrapping and to quickly be able to access
// configuration for an endpoint we will use this cached registry
//
const bootstrappedEndpoints: {[uri: string]: EvaEndpoint} = {};

// The currently pending bootstrap requests also need to be maintained for
// rapid fire bootstrap requests
//
const pendingBootstrapRequests: {[uri: string]: Promise<EvaEndpoint>} = {};

/**
 * Helper method to check if an endpoint has been bootstrapped
 *
 * @export
 * @param {string} uri The EVA backend hostname
 * @param {number} [targetApplicationId] Optional check the endpoint is bootstrapped for a specific application id
 * @returns {boolean}
 */
export function hasEndPointBootstrapped(
  uri: string,
): boolean {
  const endpoint = bootstrappedEndpoints[uri];
  return !!endpoint;
}

/**
 * Required options for bootstrapping an endpoint
 *
 * @export
 * @interface IEvaBootstrapOptions
 */
export interface IEvaBootstrapOptions {
  /**
   * The EVA endpoint to bootstrap
   *
   * @type {string}
   */
  uri: string;

  /**
   * Forces the bootstrap to be done even if the endpoint was previously
   * bootstrapped
   *
   * @type {boolean}
   */
  forceNewBootstrap?: boolean;

  /**
   * If provided the endpoint cache can be pre-heated from storage preventing
   * actual calls to the backend. Serialized data contains a bootstrapped timestamp.
   * Bootstrapped data is usually cached for 10 minutes
   *
   * @type {Storage}
   */
  storage?: EvaStorage<IEvaEndpointStorage>;
}

/**
 * This method will bootstrap an EVA endpoint or will return a previously
 * bootstrapped endpoints data.
 * The bootstrapped endpoint can be used with the EvaService class to make
 * calls the the EVA backend.
 * The EvaService class needs details from the bootstrap for configuration
 * and defaults
 *
 * @export
 * @param {IEvaBootstrapOptions} args
 * @returns
 */
export async function bootstrapEndpoint(
  args: IEvaBootstrapOptions,
): Promise<EvaEndpoint> {
  if (!args || !args.uri) {
    throw new Error('Missing required EVA endpoint uri');
  }

  const serviceSettings = getServiceSettings();

  if (args.storage) {
    // Fill the bootstrapped endpoints from storage
    //
    try {
      const storedEndpoints = JSON.parse(args.storage.getItem('evaEndpointCache') || '{}');
      for (const [endpointUrl, data] of Object.entries(storedEndpoints)) {
        const endpointData = data as IEvaEndpointSerializedData;

        bootstrappedEndpoints[endpointUrl] = new EvaEndpoint(endpointData);
      }
    } catch (storageError) {
      logger.warn('[EVA-BOOTSTRAP] Invalid stored endpoint cache', storageError);
    }
  }

  const now = +(new Date());
  const threshold = now - serviceSettings.endpointCacheDuration;

  if (
    args.forceNewBootstrap ||
    !bootstrappedEndpoints[args.uri] ||
    bootstrappedEndpoints[args.uri].bootstrappedAt < threshold
  ) {

    // Check if we have a pending bootstrap request for the same endpoint
    // If so hook into that promise and return the same result.
    //
    if (pendingBootstrapRequests[args.uri]) {
      logger.debug('[EVA-BOOTSTRAP] Request for already pending endpoint bootstrap', args.uri);
      return await pendingBootstrapRequests[args.uri];
    }

    const endpoint = new EvaEndpoint(args.uri);
    pendingBootstrapRequests[args.uri] = (async () => {
      await endpoint.bootstrap();
      return endpoint;
    })();
    await pendingBootstrapRequests[args.uri];
    delete pendingBootstrapRequests[args.uri];
    bootstrappedEndpoints[args.uri] = endpoint;

    // Update storage for future pre-heating
    //
    if (args.storage) {
      try {
        const storedEndpoints = JSON.parse(args.storage.getItem('evaEndpointCache') || '{}');
        storedEndpoints[args.uri] = endpoint.serialise();
        args.storage.setItem('evaEndpointCache', JSON.stringify(storedEndpoints));
        logger.debug('[EVA-BOOTSTRAP] Added endpoint to cache', args.uri);
      } catch (storageError) {
        logger.warn('[EVA-BOOTSTRAP] Failed to store endpoint cache', storageError);
      }
    }

    return endpoint;
  }

  const endpoint = bootstrappedEndpoints[args.uri];
  return endpoint;
}
