import { Core } from '@springtree/eva-services-core';
// tslint:disable-next-line: import-name
import ky from 'ky-universal';
import { atom, selector } from 'recoil';
import { IServiceState } from '../../builders/build-service-state';
import getEvaEndpoint from '../../helpers/get-eva-endpoint';
import userExpiration from '../../helpers/user-expiration';
import evaEndpointUrlState from '../core/eva-endpoint-url-state';
import currentUserTokenState from './current-user-token-state';

// Because the current user is used in the service state builder we cannot use
// the builder itself to make this service state
//
const recoilKey = 'currentUser';

// The stale state is used to manipulate the memoized function behaviour of recoil
// A date/time string is used as the request ID.
// See: https://recoiljs.org/docs/guides/asynchronous-data-queries#use-a-request-id
//
const staleState = atom<string>({
  key: `${recoilKey}/Stale`,
  default: `${new Date()}`,
});

// Current user request has an empty payload but adding this for consistency
//
const requestState = atom<Core.GetCurrentUser['request']>({
  key: `${recoilKey}/Request`,
  default: undefined,
});

// Persisting the request state for this service serves no purpose
//
const storageState =  selector<void>({
  key: `${recoilKey}/Storage`,
  get: () => {},
});

// The response state can be undefined (initially), the service response
// or an error.
// Additional selectors are available to target either the Error or response
//
const responseRawState = selector<Core.GetCurrentUser['response']|undefined|Error>({
  key: `${recoilKey}/Response/Raw`,
  get: async ({ get }) => {
    const evaEndpointUrl = get(evaEndpointUrlState);
    const currentUserToken = get(currentUserTokenState);
    get(staleState);

    if (
        !evaEndpointUrl ||
        !currentUserToken) {
      return;
    }

    const evaEndpoint = await getEvaEndpoint(evaEndpointUrl);

    try {
      const response = await evaEndpoint.callService(
        Core.GetCurrentUser,
        undefined,
        {
          authenticationToken: currentUserToken,
        },
      );

      return response;
    } catch (error) {
      if (error instanceof ky.HTTPError && error.message === '401') {
        console.warn(`[${recoilKey}] User token has expired`, error);
        userExpiration.tokenExpired(currentUserToken);
      } else {
        console.warn(`[${recoilKey}] Call failed`, error);
      }
      return error;
    }
  },
});
const responseResultState = selector<Core.GetCurrentUser['response']|undefined>({
  key: `${recoilKey}/Response/Result`,
  get: ({ get }) => {
    const responseRaw = get(responseRawState);
    if (responseRaw && !(responseRaw instanceof Error)) {
      return responseRaw;
    }
  },
});
const responseErrorState = selector<Error|undefined>({
  key: `${recoilKey}/Response/Error`,
  get: ({ get }) => {
    const responseRaw = get(responseRawState);
    if (responseRaw && responseRaw instanceof Error) {
      return responseRaw;
    }
  },
});

const currentUserState: IServiceState<Core.GetCurrentUser> = {
  recoilKey,
  error: responseErrorState,
  request: requestState,
  response: responseResultState,
  responseRaw: responseRawState,
  stale: staleState,
  storage: storageState,
};

export default currentUserState;
