import { IEvaServiceCallOptions } from '@springtree/eva-sdk-core-service';
import { Core, IEvaServiceDefinition } from '@springtree/eva-services-core';
import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import getEvaEndpoint from '../helpers/get-eva-endpoint';
import currentUserTokenState from '../state/current-user/current-user-token-state';
import evaEndpointUrlState from '../state/core/eva-endpoint-url-state';
import requestedOrganizationUnitIdState from '../state/core/requested-organization-unit-id-state';
import requestedOrganizationUnitQueryState from '../state/core/requested-organization-unit-query-state';
import shoppingCartState from '../state/checkout/shopping-cart-state';

/**
 * These are the options for the hook itself
 * Not to be confused with the callOptions for the EVA service call.
 *
 * @interface IUseShoppingCartAdd
 * @template SVC
 */
export interface IUseShoppingCartAdd<SVC extends IEvaServiceDefinition> {
  onSuccess?: (data: SVC['response']) => void;
  onError?: (error: Error) => void;
}

type TShoppingCartModifyingServices =
  Core.AddProductToOrder |
  Core.AddServiceProductToOrder |
  Core.AddBundleProductToOrder |
  Core.AddWishListProductToOrder
;

export const useShoppingCartAddProduct = <SVC extends IEvaServiceDefinition = TShoppingCartModifyingServices>({
  service,
  options = {},
} : {
  service: new() => SVC,
  options?: IUseShoppingCartAdd<SVC>,
}) => {
  const evaEndpointUrl = useRecoilValue(evaEndpointUrlState);
  const currentUserToken = useRecoilValue(currentUserTokenState);
  const evaRequestedOrganizationUnitID = useRecoilValue(requestedOrganizationUnitIdState);
  const evaRequestedOrganizationUnitQuery = useRecoilValue(requestedOrganizationUnitQueryState);
  const shoppingCart = useRecoilValue(shoppingCartState.response);
  const shoppingCartRequest = useRecoilValue(shoppingCartState.request);

  const setShoppingCartRequest = useSetRecoilState(shoppingCartState.request);
  const setShoppingCartStale = useSetRecoilState(shoppingCartState.stale);

  const modifyCart = useCallback(
    async ({
      payload,
      callOptions,
      orderType,
    } : {
      payload: Omit<SVC['request'], 'OrderID'|'SessionID'|'TargetOrderID'>,
      orderType: EVA.Core.OrderTypes.Sales,
      callOptions?: IEvaServiceCallOptions,
    }) => {
      try {
        const evaEndpoint = await getEvaEndpoint(evaEndpointUrl);

        // First check if we have an order and if it is not in a completed status
        // Create a new order if there is none or if it is completed
        //
        const requestedOrderId = shoppingCartRequest?.OrderID;
        const currentOrderId = shoppingCart?.ShoppingCart.ID;
        const isCompleted = shoppingCart?.ShoppingCart.IsCompleted === true;
        let newOrderId: number;
        if (!currentOrderId || isCompleted) {
          const newOrder = await evaEndpoint.callService(
            Core.CreateOrder,
            {
              Type: orderType,
            },
            {
              authenticationToken: currentUserToken,
              requestedOrganizationUnitID: evaRequestedOrganizationUnitID,
              requestedOrganizationUnitQuery: evaRequestedOrganizationUnitQuery,
              ...callOptions,
            },
          ) as EVA.Core.CreateOrderResponse;
          newOrderId = newOrder.OrderID;
        } else {
          newOrderId = currentOrderId;
        }

        const requestPayload: SVC['request'] = {
          ...payload,
        };

        // The add product from wishlist service is the only exception to the
        // target order id property in the request
        //
        const serviceName = new service().name;
        if (serviceName.match('AddWishListProductToOrder')) {
          (requestPayload as Record<string, number>).TargetOrderID = newOrderId;
        } else {
          (requestPayload as Record<string, number>).OrderID = newOrderId;
        }

        // Options are combined in such a way that the caller can override
        // the token if desired
        //
        const response = await evaEndpoint.callService(
          service,
          requestPayload,
          {
            authenticationToken: currentUserToken,
            requestedOrganizationUnitID: evaRequestedOrganizationUnitID,
            requestedOrganizationUnitQuery: evaRequestedOrganizationUnitQuery,
            ...callOptions,
          },
        );

        if (requestedOrderId !== newOrderId) {
          // Set the new current shopping cart
          //
          setShoppingCartRequest({
            ...shoppingCartRequest,
            OrderID: newOrderId,
          });
        } else {
          // Mark the existing shopping cart as being stale
          //
          setShoppingCartStale(`${new Date()}`);
        }

        if (response && options.onSuccess) {
          options.onSuccess(response);
        }

        return response;
      } catch (error) {
        console.error(`Failed to call service: ${service.name}`, error);
        if (options.onError) {
          options.onError(error);
        }
      }
    },
    [
      currentUserToken,
      evaEndpointUrl,
      evaRequestedOrganizationUnitID,
      evaRequestedOrganizationUnitQuery,
      service,
      shoppingCart,
      shoppingCartRequest,
    ],
  );

  return modifyCart;
};

export default useShoppingCartAddProduct;
