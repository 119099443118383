import { IEvaServiceCallOptions } from '@springtree/eva-sdk-core-service';
import { Core, IEvaServiceDefinition } from '@springtree/eva-services-core';
import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import getEvaEndpoint from '../helpers/get-eva-endpoint';
import currentUserTokenState from '../state/current-user/current-user-token-state';
import evaEndpointUrlState from '../state/core/eva-endpoint-url-state';
import requestedOrganizationUnitIdState from '../state/core/requested-organization-unit-id-state';
import requestedOrganizationUnitQueryState from '../state/core/requested-organization-unit-query-state';
import shoppingCartState from '../state/checkout/shopping-cart-state';

/**
 * These are the options for the hook itself
 * Not to be confused with the callOptions for the EVA service call.
 *
 * @interface IUseShoppingCartModifyOptions
 * @template SVC
 */
export interface IUseShoppingCartModifyOptions<SVC extends IEvaServiceDefinition> {
  onSuccess?: (data: SVC['response']) => void;
  onError?: (error: Error) => void;
}

type TShoppingCartModifyingServices =
  Core.CancelOrderLine |
  Core.ChangeOrderLinesToCarryOut |
  Core.ChangeOrderLinesToDelivery |
  Core.ChangeOrderLinesToPickup |
  Core.ModifyQuantityOrdered |
  Core.SetRequestedDate |
  Core.SplitOrderLine |
  Core.UpdateProductRequirementValuesForOrderLine |
  Core.UpdateSerialNumber
;

export const useShoppingCartModify = <SVC extends IEvaServiceDefinition = TShoppingCartModifyingServices>({
  service,
  options = {},
} : {
  service: new() => SVC,
  options?: IUseShoppingCartModifyOptions<SVC>,
}) => {
  const evaEndpointUrl = useRecoilValue(evaEndpointUrlState);
  const currentUserToken = useRecoilValue(currentUserTokenState);
  const evaRequestedOrganizationUnitID = useRecoilValue(requestedOrganizationUnitIdState);
  const evaRequestedOrganizationUnitQuery = useRecoilValue(requestedOrganizationUnitQueryState);
  const shoppingCart = useRecoilValue(shoppingCartState.response);

  const setShoppingCartStale = useSetRecoilState(shoppingCartState.stale);

  const modifyCart = useCallback(
    async ({
      payload,
      callOptions,
    } : {
      payload: Partial<SVC['request']>,
      callOptions?: IEvaServiceCallOptions,
    }) => {
      try {
        const evaEndpoint = await getEvaEndpoint(evaEndpointUrl);

        // Ensure we have a valid order
        //
        const orderId = shoppingCart?.ShoppingCart.ID;
        if (!orderId) {
          console.warn(`There is no active shopping cart to call ${service.name} on`);
          if (options.onError) {
            options.onError(new Error(`There is no active shopping cart to call ${service.name} on`));
          }
          return;
        }

        const requestPayload: SVC['request'] = {
          ...payload,
        };

        const serviceName = new service().name;
        if (serviceName.match('ChangeOrderLinesTo')) {
          // The ChangeOrderLinesTo... services require the OrderID
          //
          (requestPayload as Record<string, number>).OrderID = orderId;
        }

        // Options are combined in such a way that the caller can override
        // the token if desired
        //
        const response = await evaEndpoint.callService(
          service,
          requestPayload,
          {
            authenticationToken: currentUserToken,
            requestedOrganizationUnitID: evaRequestedOrganizationUnitID,
            requestedOrganizationUnitQuery: evaRequestedOrganizationUnitQuery,
            ...callOptions,
          },
        );

        // Set the current shopping cart as being stale
        //
        setShoppingCartStale(`${new Date()}`);

        if (response && options.onSuccess) {
          options.onSuccess(response);
        }

        return response;
      } catch (error) {
        console.error(`Failed to call service: ${service.name}`, error);
        if (options.onError) {
          options.onError(error);
        }
      }
    },
    [
      currentUserToken,
      evaEndpointUrl,
      evaRequestedOrganizationUnitID,
      evaRequestedOrganizationUnitQuery,
      service,
      shoppingCart,
    ],
  );

  return modifyCart;
};

export default useShoppingCartModify;
