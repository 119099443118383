/**
 * Helper method to extract all the roles a user has
 *
 * @param {EVA.Core.GetAuthorizationStructureResponse} authorizationStructure
 * @returns {{ ID: number, Name: string }}
 */
export const getRoles = ({
  authorizationStructure,
}:{
  authorizationStructure: EVA.Core.GetAuthorizationStructureResponse,
}): { ID: number, Name: string }[] => {
  const userRoles = authorizationStructure.Roles.map((role) => {
    return { ID: role.RoleID, Name: role.RoleName };
  });
  return userRoles;
};

/**
 * Returns all the roles that match for a given functionality
 * and type (scope or elevated). Optionally targeting a specific organization unit.
 *
 * @param {({
 *   authorizationStructure: EVA.Core.GetAuthorizationStructureResponse,
 *   organizationUnitId?: number,
 *   functionality: string,
 *   scope: EVA.Core.FunctionalityScope,
 *   type: 'elevated'|'scoped',
 * })} {
 *   authorizationStructure,
 *   organizationUnitId,
 *   functionality,
 *   scope,
 *   type = 'scoped',
 * }
 * @returns
 */
export const getMatchingRoles = ({
  authorizationStructure,
  organizationUnitId,
  functionality,
  scope,
  type = 'scoped',
}:{
  authorizationStructure: EVA.Core.GetAuthorizationStructureResponse,
  organizationUnitId?: number,
  functionality: string,
  scope: EVA.Core.FunctionalityScope,
  type?: 'elevated'|'scoped',
}) => {
  const matchingRoles = authorizationStructure.Roles.filter(
    (role) => {
      // If an organization unit was targeted omit the role if the id is not found
      //
      if (organizationUnitId && role.OrganizationUnitIDs.indexOf(organizationUnitId) === -1) {
        return false;
      }

      if (
        type === 'scoped' &&
        role.ScopedFunctionalities[functionality] &&
        (role.ScopedFunctionalities[functionality] & scope) !== 0
      ) {
        return true;
      }

      if (
        type === 'elevated' &&
        role.ElevationFunctionalities[functionality] &&
        (role.ElevationFunctionalities[functionality] & scope) !== 0
      ) {
        return true;
      }

      return false;
    },
  );

  return matchingRoles;
};

/**
 * Returns if there is any matching role for a given functionality
 * and type (scope or elevated). Optionally targeting a specific organization unit.
 *
 * @param {({
 *   authorizationStructure: EVA.Core.GetAuthorizationStructureResponse,
 *   organizationUnitId?: number,
 *   functionality: string,
 *   scope: EVA.Core.FunctionalityScope,
 *   type: 'elevated'|'scoped',
 * })} {
 *   authorizationStructure,
 *   organizationUnitId,
 *   functionality,
 *   scope,
 *   type = 'scoped',
 * }
 * @returns {boolean}
 */
export const hasFunctionality = ({
  authorizationStructure,
  organizationUnitId,
  functionality,
  scope,
  type = 'scoped',
}:{
  authorizationStructure: EVA.Core.GetAuthorizationStructureResponse,
  organizationUnitId?: number,
  functionality: string,
  scope: EVA.Core.FunctionalityScope,
  type?: 'elevated'|'scoped',
}): boolean => {
  const matchingRoles = getMatchingRoles({
    authorizationStructure,
    organizationUnitId,
    functionality,
    scope,
    type,
  });

  return matchingRoles.length > 0;
};
