import { IEvaServiceCallOptions } from '@springtree/eva-sdk-core-service';
import { IEvaServiceDefinition } from '@springtree/eva-services-core';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import getEvaEndpoint from '../helpers/get-eva-endpoint';
import currentUserTokenState from '../state/current-user/current-user-token-state';
import evaEndpointUrlState from '../state/core/eva-endpoint-url-state';
import requestedOrganizationUnitIdState from '../state/core/requested-organization-unit-id-state';
import requestedOrganizationUnitQueryState from '../state/core/requested-organization-unit-query-state';

/**
 * These are the options for the hook itself
 * Not to be confused with the callOptions for the EVA service call.
 *
 * @interface IUseCallServiceOptions
 * @template SVC
 */
export interface IUseCallServiceOptions<SVC extends IEvaServiceDefinition> {
  onSuccess?: (data: SVC['response']) => void;
  onError?: (error: Error) => void;
}

/**
 * This hook is a convenience for calling services on an EVA endpoint with
 * the current endpoint and user token from the recoil state.
 *
 * @template SVC
 * @param {new() => SVC} service The service to call
 * @param {IEvaServiceCallOptions} [options] The options for the service call
 * @param {IUseCallServiceOptions<SVC>} [hookOptions] The options for the hook
 * @returns
 */
const useCallService = <SVC extends IEvaServiceDefinition>({
  service,
  options,
} : {
  service: new() => SVC,
  options?: IUseCallServiceOptions<SVC>,
}) => {
  const evaEndpointUrl = useRecoilValue(evaEndpointUrlState);
  const currentUserToken = useRecoilValue(currentUserTokenState);
  const evaRequestedOrganizationUnitID = useRecoilValue(requestedOrganizationUnitIdState);
  const evaRequestedOrganizationUnitQuery = useRecoilValue(requestedOrganizationUnitQueryState);

  const callService = useCallback(
    async (requestPayload?: SVC['request'], callOptions?: IEvaServiceCallOptions) => {
      try {
        const evaEndpoint = await getEvaEndpoint(evaEndpointUrl);

        // Options are combined in such a way that the caller can override
        // the token if desired
        //
        const response = await evaEndpoint?.callService(
          service,
          requestPayload,
          {
            authenticationToken: currentUserToken,
            requestedOrganizationUnitID: evaRequestedOrganizationUnitID,
            requestedOrganizationUnitQuery: evaRequestedOrganizationUnitQuery,
            ...callOptions,
          },
        );

        if (response && options?.onSuccess) {
          options.onSuccess(response);
        }

        return response;
      } catch (error) {
        console.error(`Failed to call service: ${service.name}`, error);
        if (options?.onError) {
          options.onError(error);
        }
      }
    },
    [
      currentUserToken,
      evaEndpointUrl,
      evaRequestedOrganizationUnitID,
      evaRequestedOrganizationUnitQuery,
      options,
      service,
    ],
  );

  return callService;
};

export default useCallService;
