import { EvaEndpoint, IEvaServiceCallOptions } from '@springtree/eva-sdk-core-service';
import { IEvaServiceDefinition } from '@springtree/eva-services-core';
import { atom, GetRecoilValue, RecoilState, RecoilValueReadOnly, selector } from 'recoil';

// Import the 2 root state atoms
// We also need the current user response to determine the type of user
//
import currentUserTokenState from '../state/current-user/current-user-token-state';
import evaEndpointUrlState from '../state/core/eva-endpoint-url-state';
import requestedOrganizationUnitIdState from '../state/core/requested-organization-unit-id-state';
import requestedOrganizationUnitQueryState from '../state/core/requested-organization-unit-query-state';
import getEvaEndpoint from '../helpers/get-eva-endpoint';

export interface IAnonymousServiceState<SVC extends IEvaServiceDefinition> {
  error: RecoilValueReadOnly<Error|undefined>;
  recoilKey: string;
  request: RecoilState<SVC['request']>;
  response: RecoilValueReadOnly<SVC['response']>;
  responseRaw: RecoilValueReadOnly<SVC['response']|Error|undefined>;
  stale: RecoilState<string>;
}

/**
 * Build the state values for an EVA service call.
 * As a bare minimum you need to supply the service definition from the SDK.
 *
 * The recoil `key` needs to be unique and will default to the service name.
 * If you require the same service for different use cases you must supply a
 * unique key name which should also indicate its intended purpose.
 * As a convenience you can use the `requireCustomer` and `requireEmployee`
 * flags to prevent service calls from going out depending on the current user.
 *
 * @template SVC
 * @param {{
 *     allowEmptyRequest?: boolean,
 *     service: new () => SVC,
 *     defaultRequest?: SVC['request'],
 *     key?: string,
 *     requireCustomer?: boolean,
 *     requireEmployee?: boolean,
 *     requireLoggedIn?: boolean,
 *     beforeRequest?: (params: { req: SVC['request'], get: GetRecoilValue }) => SVC['request'],
 *   }} {
 *     allowEmptyRequest = true,
 *     service,
 *     defaultRequest,
 *     key,
 *     requireCustomer,
 *     requireEmployee,
 *     requireLoggedIn,
 *     beforeRequest,
 *   }
 * @returns {IAnonymousServiceState<SVC>}
 */
const buildAnonymousServiceState = <SVC extends IEvaServiceDefinition>(
  {
    allowEmptyRequest = true,
    service,
    defaultRequest,
    key,
    beforeRequest,
    customRequest,
  }:
  {
    allowEmptyRequest?: boolean,
    service: new () => SVC,
    defaultRequest?: SVC['request'],
    key?: string,
    beforeRequest?: (params: { req: SVC['request'], get: GetRecoilValue }) => SVC['request'],
    customRequest?: (params: { endpoint: EvaEndpoint, payload?: SVC['request'], options: IEvaServiceCallOptions}) => Promise<SVC['response']>,
  },
): IAnonymousServiceState<SVC> => {
  // Use provided key but default from service
  //
  const recoilKey = key || new service().name;

  // The stale state is used to manipulate the memoized function behaviour of recoil
  // A date/time string is used as the request ID.
  // See: https://recoiljs.org/docs/guides/asynchronous-data-queries#use-a-request-id
  //
  const staleState = atom<string>({
    key: `${recoilKey}/Stale`,
    default: `${new Date()}`,
  });

  const requestState = atom<SVC['request']>({
    key: `${recoilKey}/Request`,
    default: selector({
      key: `${recoilKey}/Default`,
      get: () => defaultRequest,
    }),
  });

  // The response state can be undefined (initially), the service response
  // or an error.
  // Additional selectors are available to target either the Error or response
  //
  const responseRawState = selector<SVC['response']|undefined|Error>({
    key: `${recoilKey}/Response/Raw`,
    get: async ({ get }) => {
      const evaEndpointUrl = get(evaEndpointUrlState);
      const evaRequestedOrganizationUnitID = get(requestedOrganizationUnitIdState);
      const evaRequestedOrganizationUnitQuery = get(requestedOrganizationUnitQueryState);
      let requestPayload = get(requestState);
      get(staleState);

      // Even though we do not need the user token we are depending on it to
      // trigger new fetches of anonymous data when the recoil root is rebuilt
      //
      get(currentUserTokenState);

      if (!evaEndpointUrl) {
        return;
      }

      // If the caller provided a method to modify the request call it here
      // before sending it to the back-end
      //
      if (typeof beforeRequest === 'function') {
        try {
          requestPayload = beforeRequest({ get, req: requestPayload });
        } catch (error) {
          // console.warn(`[${recoilKey}] Request prepare call abort`, error);
          return;
        }
      }

      if (!allowEmptyRequest && Object.keys(requestPayload as Object || {}).length === 0) {
        return;
      }

      try {
        const evaEndpoint = await getEvaEndpoint(evaEndpointUrl);
        const options: IEvaServiceCallOptions = {
          requestedOrganizationUnitID: evaRequestedOrganizationUnitID,
          requestedOrganizationUnitQuery: evaRequestedOrganizationUnitQuery,
        };

        // Use the custom request implementation if provided. Can be used for
        // composite services are call using a custom XHR
        //
        const response = customRequest ?
          await customRequest({ options, endpoint: evaEndpoint, payload: requestPayload })
        : await evaEndpoint.callService(
            service,
            requestPayload,
            options,
          )
        ;

        return response;
      } catch (error) {
        console.warn(`[${recoilKey}] Call failed`, error);
        return error;
      }
    },
  });

  const responseResultState = selector<SVC['response']|undefined>({
    key: `${recoilKey}/Response/Result`,
    get: ({ get }) => {
      const responseRaw = get(responseRawState);
      if (responseRaw && !(responseRaw instanceof Error)) {
        return responseRaw;
      }
    },
  });

  const responseErrorState = selector<Error|undefined>({
    key: `${recoilKey}/Response/Error`,
    get: ({ get }) => {
      const responseRaw = get(responseRawState);
      if (responseRaw && responseRaw instanceof Error) {
        return responseRaw;
      }
    },
  });

  const state: IAnonymousServiceState<SVC> = {
    recoilKey,
    error: responseErrorState,
    request: requestState,
    response: responseResultState,
    responseRaw: responseRawState,
    stale: staleState,
  };

  return state;
};

export default buildAnonymousServiceState;
